







































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { GroupDetail, GroupStorage } from '@/models'
import GroupService from '@/services/GroupService'
import PreviewText from '@/helpers/PreviewText'

/**
 * f2-101
 * サークル設定 基本情報画面
 */
@Component
export default class GroupInfoPage extends Vue {
  private groupDetail: GroupDetail = new GroupDetail()
  private groupStorage: GroupStorage = new GroupStorage()
  private modalMess: string = ''
  private roleAdmin: boolean = this.$store.state.userInfo.user.group_role
    .role_admin
  private noGroupAvatar =
    window.location.origin + require('@/assets/images/group/avatar_default_1.png')
  private groupTagArea: any[] = []
  private districtArea: any[] = []
  private check_group_all: any[] = []
  private listAreaLevel1: any[] = []
  private listAreaLevel2: any[] = []
  private listAreaLevel3: any[] = []

  created() {
    this.getGroupInformation()
    this.getAreaByGroup()
  }

  getGroupInformation() {
    GroupService.getGroupById(this.$route.params.groupId)
      .then(res => {
        if (res.status === 200) {
          this.groupDetail = res.data
          this.groupStorage = new GroupStorage(res.data)
          this.groupTagArea = res.data.group_group_area_tag
          this.districtArea = res.data.group_group_tag_district
          this.check_group_all = res.data.group_group_tag_area_all
        }
      })
      .catch(err => {
        this.modalMess = this.$t('common.message.uncatch_error') as string
        this.$bvModal.show('modal-error')
      })
  }

  getAreaByGroup() {
    GroupService.getAreaByGroup(this.$route.params.groupId)
      .then(res => {
        if (res.status === 200) {
          this.listAreaLevel1 = res.data.areaLevel1
          this.listAreaLevel2 = res.data.areaLevel2
          this.listAreaLevel3 = res.data.areaLevel3
        }
      })
      .catch(err => {
        console.log(err);
        
      })
  }

  goToEditPage() {
    return ({
      name: 'group-setting-information-edit',
      params: { updateGroupInfo: 'true' }
    })
  }

  confirmCleanBin() {
    this.$bvModal.show('modal-confirm')
  }

  cleanBin() {
    this.$blockui.show()
    GroupService.cleanBin(this.$route.params.groupId)
      .then(res => {
        if (res.status === 200) {
          this.getGroupInformation()
          this.modalMess = this.$t('common.message.cleaned') as string
          this.$bvModal.show('modal-success')
        }
      })
      .catch(() => {
        this.modalMess = this.$t('common.message.clean_fail') as string
        this.$bvModal.show('modal-error')
      })
      .finally(() => this.$blockui.hide())
  }

  /**
   * format date
   */
  formatDate(date: string) {
    const yyyymmdd = this.$t('common.date_format.yyyy_mm_dd') as string
    return this.moment(date).format(yyyymmdd)
  }

  /**
   * Handle long text
   */
  handleLongText(text: string) {
    return PreviewText.covertToPreviewText(text, 40)
  }

  /**
   * Handle group storage description
   */
  handleGroupStorge(groupStorage: GroupStorage) {
    return (
      [
        Math.floor(groupStorage.storage_used * 100) / 100 + groupStorage.type,
        groupStorage.storage_capacity + groupStorage.type
      ].join('/') + ` (${Math.floor(groupStorage.percent)}%)`
    )
  }

  showModal() {
    this.$bvModal.show('upSizeStorage')
  }
}
